import { useCallback, useEffect } from 'react';
import { globalHistory } from '@reach/router';

const DynamicTitle = ({ title, dynamicTitle = [] }) => {
  const arr = [];

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        arr.map(i => clearInterval(i));
      }
    });
  }, [arr]);

  const dynamicTitleHandler = useCallback(() => {
    dynamicTitle.push({
      pageTitle:
        title ||
        'Shopmonkey | Auto Repair Shop Management Software | Shopmonkey',
      delayTrigger:
        parseInt(dynamicTitle[dynamicTitle.length - 1].delayTrigger, 10) + 10,
    });

    dynamicTitle.forEach(dynamic => {
      const intervalId = setInterval(() => {
        document.title = dynamic.pageTitle;
      }, parseInt(dynamic.delayTrigger, 10) * 1000);
      arr.push(intervalId);
    });
  }, [arr, dynamicTitle, title]);

  useEffect(() => {
    if (dynamicTitle.length > 0) {
      dynamicTitleHandler();
    }
  }, [dynamicTitle.length, dynamicTitleHandler]);

  return null;
};

export default DynamicTitle;
