import React, { useState, useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../seo';
import Layout from '../layout';
import ParallaxCache from '../../utils/ParallaxCache';
import componentGenerator from '../../utils/componentGenerator';
import DynamicTitle from '../dynamicTitle';
import useGoogleOptimize from '../../hooks/useGoogleOptimize';
import useGeneralPages from '../../hooks/useGeneralPages';
import GeneralHead from '../generalHead';

const GeneralPage = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState('monthly');

  const variant = useGoogleOptimize({
    experimentId: '7JZvj3t_Rb-aA-Hr0VdjlQ',
    eventId: 'optimize.activate.generalPagesTest',
  });
  const generalPages = useGeneralPages();
  const [page, setPage] = useState(generalPages[0]);

  console.log('Optimize Variant: ', variant);

  useEffect(() => {
    if (variant !== null) {
      setPage(generalPages[variant]);
    }
  }, [generalPages, variant]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  console.log('Page: ', page);

  if (loading) return null;

  const isDark =
    page?.components &&
    ((page?.components[1]?.background &&
      Boolean(page?.components[1].background.toLowerCase() !== 'light')) ||
      page?.components[1]?.__typename === 'DatoCmsHeroCarouselComponent');

  return (
    <ParallaxProvider>
      {typeof window !== 'undefined' && <ParallaxCache />}
      <Layout
        isDark={isDark}
        forceAnnouncement={page?.components?.[0]?.announcement}
        location={location}
      >
        <DynamicTitle
          title={page?.metaTags?.title}
          dynamicTitle={page?.dynamicTitle}
        />
        {page?.components &&
          componentGenerator(
            page?.components,
            page?.slug,
            page?.breadcrumb,
            isDark,
            period,
            setPeriod
          )}
      </Layout>
    </ParallaxProvider>
  );
};

export const Head = () => {
  const generalPages = useGeneralPages();
  const { seo, headHtml, indexable, schemaMarkup } = generalPages[0];

  return (
    <GeneralHead>
      <SEO
        seo={seo}
        headHtml={headHtml}
        indexable={indexable}
        schemaMarkup={schemaMarkup}
      />
    </GeneralHead>
  );
};

export default GeneralPage;
