import { graphql, useStaticQuery } from 'gatsby';

const useGeneralPages = () => {
  const { pages } = useStaticQuery(pagesQuery);

  const generalPages = {
    0: pages?.nodes?.filter(page => page?.slug === 'general-c')[0],
    1: pages?.nodes?.filter(page => page?.slug === 'general-b')[0],
    2: pages?.nodes?.filter(page => page?.slug === 'general-c')[0],
  };

  return generalPages;
};

export default useGeneralPages;

export const pagesQuery = graphql`
  query {
    pages: allDatoCmsWebsitePage(
      filter: { slug: { in: ["general-b", "general-c"] } }
    ) {
      nodes {
        headHtml
        slug
        indexable
        dynamicTitle: announcement {
          id
          pageTitle
          delayTrigger
        }
        metaTags {
          title
        }
        seo: seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        breadcrumb {
          id
          label
          link
        }
        schemaMarkup {
          id
          schema
        }
        components {
          __typename
          ... on DatoCmsJobListingBlock {
            id
          }
          ... on DatoCmsHeaderComponent {
            ...datoCmsHeaderComponent
          }
          ... on DatoCmsComponentHero {
            ...datoCmsComponentHero
          }
          ... on DatoCmsAccordionComponent {
            ...datoCmsAccordionComponent
          }
          ... on DatoCmsGridComponent {
            ...datoCmsGridComponent
          }
          ... on DatoCmsSwitchbackComponent {
            ...datoCmsSwitchbackComponent
          }
          ... on DatoCmsTestimonialCarouselComponent {
            ...datoCmsTestimonialCarouselComponent
          }
          ... on DatoCmsCarouselComponent {
            ...datoCmsCarouselComponent
          }
          ... on DatoCmsCardDeckComponent {
            ...datoCmsCardDeckComponent
          }
          ... on DatoCmsConversionPanelComponent {
            ...datoCmsConversionPanelComponent
          }
          ... on DatoCmsPricingCardDeck {
            internalName
            savingTag
            pricingCards {
              internalName
              horizontal
              active
              cardTitle
              subhead
              monthlyPrice
              annualPrice
              listContent {
                id
                internalName
                heading
                subhead
                icon {
                  internalName
                  image {
                    gatsbyImageData(
                      imgixParams: { fm: "webp", auto: "compress", q: 35 }
                    )
                    url
                  }
                }
              }
              link {
                label
                url
              }
            }
          }
          ... on DatoCmsPricingBlockContainer {
            internalName
            blockSection {
              internalName
              sectionTitle
              subhead
              planPerks {
                internalName
                perkTitle
                tooltipContent
                perkBlocks {
                  internalName
                  perkIcon {
                    internalName
                    image {
                      gatsbyImageData(
                        imgixParams: { fm: "webp", auto: "compress", q: 35 }
                      )
                      url
                    }
                  }
                  perkText
                }
              }
              planBlock {
                pricingCards {
                  internalName
                  cardTitle
                  annualPrice
                  monthlyPrice
                  link {
                    label
                    url
                  }
                  icon {
                    image {
                      gatsbyImageData(
                        imgixParams: { fm: "webp", auto: "compress", q: 35 }
                      )
                      url
                    }
                  }
                }
              }
            }
          }
          ... on DatoCmsGraphic {
            id
            internalName
            fullImage {
              image {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                url
              }
            }
            tabletImage {
              image {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                url
              }
            }
            mobileImage {
              image {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                url
              }
            }
          }
          ... on DatoCmsInvestorCardDeck {
            id
            internalName
            heading
            scrollId
            companyList {
              id
              name
              logo {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                url
              }
            }
          }
          ... on DatoCmsIllustration {
            id
            internalName
            backgroundColor {
              hex
            }
            heading
            subhead
            graphic {
              internalName
              image {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                  ...GatsbyDatoCmsFluid
                }
                url
              }
            }
            caption
          }
          ... on DatoCmsGatedList {
            id
            internalName
            heading
            subhead
            backgroundColor {
              hex
            }
            listBlock {
              id
              heading
              subhead
              icon {
                image {
                  gatsbyImageData(
                    imgixParams: {
                      fm: "webp"
                      auto: "compress"
                      maxW: 1080
                      fit: "clip"
                      q: 35
                    }
                  )
                  url
                }
              }
              tagLabel
            }
            images {
              internalName
              image {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                  ...GatsbyDatoCmsFluid
                }
                url
              }
              backgroundImage {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                  ...GatsbyDatoCmsFluid
                }
                url
              }
            }
          }
          ... on DatoCmsModularContentComponent {
            id
            content {
              __typename
              ... on DatoCmsRichText {
                content
              }
              ... on DatoCmsForm {
                id
                form {
                  formId
                  heading
                  internalName
                  subhead
                }
              }
            }
          }
          ... on DatoCmsSocialProofComponent {
            id
            internalName
            heading
            headingKicker
            subhead
            variant
            pressMentions {
              name
              featuredArticle
              lightLogo {
                image {
                  url
                  gatsbyImageData(
                    height: 32
                    imgixParams: { fm: "webp", auto: "compress", q: 35 }
                  )
                }
              }
              darkLogo {
                image {
                  url
                  gatsbyImageData(
                    height: 32
                    imgixParams: { fm: "webp", auto: "compress", q: 35 }
                  )
                }
              }
            }
          }
          ... on DatoCmsComponentVideo {
            ...datoCmsComponentVideo
          }
          ... on DatoCmsComponentTestimonial {
            id
            internalName
            message: body
            date(formatString: "MMMM DD, YYYY")
            headline
            headingKicker
            variant
            shopType
            starCount
            geolocation
            switchedFrom
            reviewLocation
            isExternalReview
            originalReviewLink
            video {
              internalName
              youtubeUrl
              heading
              videoThumbnail {
                ...datoCmsWebsiteImage
              }
            }
            person {
              role
              internalName
              firstName
              lastName
              headshot {
                image {
                  gatsbyImageData(
                    imgixParams: {
                      fm: "webp"
                      auto: "compress"
                      maxW: 1080
                      fit: "clip"
                      q: 35
                    }
                  )
                  url
                }
              }
              company {
                name
              }
            }
          }
          ... on DatoCmsWebsiteForm {
            id
            internalName
            heading
            formId
          }
          ... on DatoCmsSingleInstanceComponent {
            id
            internalName
            variant
          }
          ... on DatoCmsComponentUseCase {
            ...datoCmsComponentUseCase
          }
          ... on DatoCmsComponentFundingBar {
            id
            heading
            theme
            fundingSources {
              id
              about
              companySize
              monotoneLogo {
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
                url
                title
                fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
          ... on DatoCmsComponentHistory {
            ...datoCmsComponentHistory
          }
          ... on DatoCmsMetricsComponent {
            ...datoCmsMetricsComponent
          }
          ... on DatoCmsExitIntentComponent {
            id
            heading
            formLabel
            formId
            label
            internalName
            originalId
            subhead
            calltoaction
            button
            image {
              image {
                filename
                alt
                title
                url
                gatsbyImageData(
                  imgixParams: {
                    fm: "webp"
                    auto: "compress"
                    maxW: 1080
                    fit: "clip"
                    q: 35
                  }
                )
              }
              id
            }
          }
          ... on DatoCmsComponentTabbedSwitcher {
            ...datoCmsComponentTabbedSwitcher
          }
          ... on DatoCmsComponentAward {
            id
            internalName
            theme
            heading
            callToAction {
              ...datoCmsCallToAction
            }
            featuredContent {
              alt
              filename
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                ...GatsbyDatoCmsFluid
              }
              url
            }
          }
          ... on DatoCmsSingleDataMigrationAnimation {
            ...datoCmsSingleDataMigrationAnimation
          }
          ... on DatoCmsComponentSocialMediaCallout {
            ...datoCmsComponentSocialMediaCallout
          }
          ... on DatoCmsComponentNewsletter {
            ...datoCmsComponentNewsletter
          }
          ... on DatoCmsComponentListing {
            ...datoCmsComponentListing
          }
        }
      }
    }
  }
`;
